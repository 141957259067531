import { combineReducers, configureStore } from "@reduxjs/toolkit";
import apiReducer from "../slices/slice";
import chatReducer from "../slices/chat.slice";
import { api } from "../api/api";
import { chatApi } from "../api/chat.api";

export const rootReducer = combineReducers({
  api: apiReducer,
  [api.reducerPath]: api.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  chat: chatReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      api.middleware,
      chatApi.middleware,
    ]),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
});

export default store;
