import styles from "./chatPage.module.css";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import Peer from "simple-peer";
import { useAppSelector } from "../../services/hooks";
import {
  appointmentSelector,
  chatConnectionSelector,
  chatDataSelector,
  chatStateSelector,
} from "../../services/selectors";
import { useLazyGetAppointmentByIdQuery } from "../../services/api/api";
import { useParams } from "react-router-dom";
import { useInitializeConnectionQuery } from "../../services/api/chat.api";
import { useActions } from "../../services/hooks/useActions";
import ChatSideBarTest from "../../components/chatSidebar/chatSidebarTest";
import ChatTest from "../../components/chatTherapist/chatTest";
import { IUserChatData } from "../../services/types";
import Loader from "../../components/loader/loader";
import { useSelector } from "react-redux";
import VideoCallWindow from "../../components/videoCallWindow/videoCallWindow";
import { videoCallInitialState } from "../../services/slices/chat.slice";
import Modal from "../../components/modal/Modal";
import CallEndedNotificationPopup from "../../components/callEndedNotification/callEndedNotificationPopup";
import MediaDevicesErrorPopup from "../../components/mediaDevicesErrorPopup/mediaDevicesErrorPopup";
import { END_CALL_CODE } from "../../utils/utils";

function ChatPage() {
  const { userId, appointmentId } = useParams();
  const connection = useAppSelector(chatConnectionSelector);
  const chatData = useAppSelector(chatDataSelector);
  const appointment = useAppSelector(appointmentSelector);
  const chatState = useSelector(chatStateSelector);
  const [myStream, setMyStream] = useState<MediaStream | undefined>(undefined);
  const [getAppointmentById] = useLazyGetAppointmentByIdQuery();
  const {
    setChatData,
    setUserConnected,
    setUserDisconnected,
    setStartCall,
    // setMyStream,
    setUserStream,
    setCall,
    setCallAccepted,
    setOffer,
    setCallEnded,
  } = useActions();
  const peerRef = useRef<Peer.Instance | null>(null);
  const [receiver, setReceiver] = useState<IUserChatData | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showMediaDevicesErrorPopup, setShowMediaDevicesErrorPopup] =
    useState(false);

  // Memoized value for appointment activity status
  const isAppointmentActive = useMemo(
    () => appointment && appointment?.UsersId === Number(userId),
    [appointment, userId]
  );

  // Lazy initialization of connection query
  useInitializeConnectionQuery({
    userId: userId || "",
    appointmentId: appointmentId || "",
    shouldConnect: Boolean(!appointment?.EndTime),
  });

  // Fetch appointment data
  useEffect(() => {
    if (appointmentId) {
      getAppointmentById(appointmentId).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [appointmentId, getAppointmentById]);

  // Update receiver if chatData changes
  useEffect(() => {
    if (receiver) {
      const updated = chatData?.find((u) => u.userId === receiver.userId);
      setReceiver(updated || chatData[0]);
    } else {
      setReceiver(chatData[0]);
    }
  }, [chatData, receiver]);

  // Handle media stream access
  useEffect(
    () => {
      if (appointment && !appointment.EndTime) {
        if (navigator.mediaDevices?.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((currentStream) => {
              setMyStream(currentStream);
            })
            .catch((err) => {
              setShowMediaDevicesErrorPopup(true);
              console.log(2, err);
            });
        } else {
          setShowMediaDevicesErrorPopup(true);
          console.error(
            "getUserMedia is not supported in this browser or environment."
          );
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointment]
  );
  //leave call if therapist goes off-line
  useEffect(() => {
    if (!receiver?.online && chatState.call.isReceivingCall) {
      leaveCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiver]);

  // Add viewport meta tag for mobile experience
  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content =
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0";
    document.head.appendChild(metaTag);
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  // Clean up peer connection on component unmount
  useEffect(() => {
    return () => {
      peerRef.current?.destroy();
    };
  }, []);

  // Event handlers for Peer connection
  function handleReceiveOffer(data: {
    offer: string;
    from: { name: string; avatar?: string };
  }) {
    setCall({ isReceivingCall: true, from: data.from });
    setOffer(data.offer);
  }

  const answerCall = useCallback(
    () => {
      setCallEnded(undefined);
      peerRef.current = new Peer({
        initiator: false,
        trickle: false,
        stream: myStream,
        config: {
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun1.l.google.com:19302" },
            { urls: "stun:stun.services.mozilla.com" },
          ],
        },
      });

      peerRef.current.on("signal", (data) => {
        connection?.invoke(
          "SendAnswer",
          receiver?.userId,
          JSON.stringify(data)
        );
      });

      peerRef.current.on("stream", (stream) => setUserStream(stream));

      if (chatState?.offer) {
        peerRef.current.signal(JSON.parse(chatState.offer));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chatState?.offer,
      // chatState.myStream,
      connection,
      receiver?.userId,
      setUserStream,
    ]
  );

  const leaveCall = useCallback(() => {
    setCall(videoCallInitialState);
    setCallAccepted(false);
    setStartCall(false);
    if (peerRef.current) peerRef.current.destroy();
  }, [setCall, setCallAccepted, setStartCall]);

  const handleReceiveAnswer = useCallback(
    (answer: string) => peerRef.current?.signal(answer),
    []
  );

  useEffect(() => {
    if (chatState) {
      leaveCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatState.callEnded, leaveCall]);

  // Setup SignalR event listeners
  useEffect(() => {
    if (connection) {
      connection.on("ReceiveChatData", (data: IUserChatData[]) => {
        setChatData(data);
      });
      connection.on("ReceiveError", (data: any) => {
        console.log(data);
      });
      connection.on("UserConnected", (data: number) => {
        setUserConnected(data);
      });
      connection.on("UserDisconnected", (disconnectedUserId) => {
        console.log("User disconnected:", disconnectedUserId);
        setUserDisconnected(disconnectedUserId);
      });
      connection.on(
        "ReceiveOffer",
        (offer: { offer: string; from: { name: string; avatar?: string } }) => {
          handleReceiveOffer(offer);
        }
      );
      connection.on("ReceiveAnswer", (answer: string) => {
        handleReceiveAnswer(answer);
      });
      // connection.on("EndCall", (by: string) => {
      //   handleEndCall(by);
      // });

      return () => {
        connection.stop();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, setChatData, setUserConnected, setUserDisconnected]);

  const handleSendMessage = useCallback(
    (value: string, type: string) => {
      const message = {
        senderId: Number(userId),
        content: value,
        receiverId: receiver?.userId,
        appointmentId: Number(appointmentId) || null,
        type,
      };
      connection?.invoke("SendMessage", message);
    },
    [userId, receiver?.userId, appointmentId, connection]
  );

  if (isLoading) return <Loader />;

  return (
    <section className={`${styles.chatPage} ${styles.nooverflow}`}>
      {isAppointmentActive ? (
        <>
          <ChatTest receiver={receiver} handleSendMessage={handleSendMessage} />
          <ChatSideBarTest setReceiver={setReceiver} />
        </>
      ) : (
        <p className={styles.noReceiver}>הפגישה לא קיימת</p>
      )}
      {chatState.call.isReceivingCall && (
        <VideoCallWindow
          onClose={() => setStartCall(false)}
          answerCall={answerCall}
          myStream={myStream}
          leaveCall={() => {
            leaveCall();
            connection?.invoke("SendMessage", {
              type: END_CALL_CODE,
              senderId: Number(userId),
              content: `${END_CALL_CODE}:by:${appointment?.FullName || ""}`,
              receiverId: receiver?.userId,
            });
          }}
        />
      )}
      {chatState.callEnded && (
        <Modal onClose={() => setCallEnded(undefined)} small={true}>
          <CallEndedNotificationPopup
            by={chatState.callEnded}
            onClose={() => setCallEnded(undefined)}
          />
        </Modal>
      )}
      {showMediaDevicesErrorPopup && (
        <Modal onClose={() => setShowMediaDevicesErrorPopup(false)}>
          <MediaDevicesErrorPopup
            onClose={() => setShowMediaDevicesErrorPopup(false)}
          />
        </Modal>
      )}
    </section>
  );
}

export default ChatPage;
