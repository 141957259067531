import { createSlice } from "@reduxjs/toolkit";
import {
  IAppointment,
  IState,
  IUser,
  TGetQuestionByIdResponse,
  TQuestion,
  TReportData,
  TServerMessage,
} from "../types";
import { api } from "../api/api";
import { formatDateTimeInIsrael, prevencherId } from "../../utils/utils";

const initialState: IState = {
  userId: "",
  user: null,
  questionnaire: undefined,
  questionnaireCompaniesId: 0,
  allQuestions: undefined,
  question: undefined,
  questionsOptions: [],
  messages: [],
  updates: [],
  currentQuestionId: -1,
  isEnd: false,
  error: "",
  allAnswers: [],
  appointment: null,
};

const updateStateFromPayload = (
  state: IState,
  payload: TGetQuestionByIdResponse
) => {
  if (!payload.Questions) {
    state.error = "משהו השתבש, אנא נסה שוב";
  } else {
    state.question = payload.Questions;
    state.currentQuestionId = payload.Questions?.Id;
    state.questionsOptions = payload?.QuestionsOptions || [];
    state.isEnd = payload?.Questions?.IsEnd;
    state.messages = [
      ...state.messages,
      {
        messageId: payload.Questions.Id.toString(),
        id: prevencherId,
        date: formatDateTimeInIsrael(new Date()),
        message: payload.Questions.Desc,
      },
    ];
  }
};

export const slice = createSlice({
  name: "api",
  initialState,
  reducerPath: "api",
  reducers: {
    setMessages: (state: IState, { payload }) => {
      return {
        ...state,
        messages: [...state.messages, payload],
      };
    },
    setOptions: (state: IState, { payload }) => {
      return {
        ...state,
        questionsOptions: payload,
      };
    },
    setAnswers: (state: IState, { payload }) => {
      const newAnswer = { ...payload, AnswerDate: null };
      return {
        ...state,
        allAnswers: [...state.allAnswers, newAnswer],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.getCurrentQuestionnaireByName.matchFulfilled,
        (state: IState, { payload }: { payload: TReportData }) => {
          return {
            ...state,
            questionnaire: payload.Questionnaire,
            allQuestions: payload.Questions.sort((a, b) => a.Seq - b.Seq),
            questionnaireCompaniesId: payload.QuestionnaireCompanies.Id,
          };
        }
      )
      .addMatcher(
        api.endpoints.getQuestionsByQuestionnaireId.matchFulfilled,
        (
          state: IState,
          { payload }: { payload: { Questions: TQuestion }[] }
        ) => {
          return {
            ...state,
            allQuestions: payload
              .map((q) => q.Questions)
              .sort((a, b) => a.Seq - b.Seq),
          };
        }
      )
      .addMatcher(
        api.endpoints.getMyDayInfo.matchFulfilled,
        (
          state: IState,
          { payload }: { payload: { Messages: TServerMessage[] } }
        ) => {
          return {
            ...state,
            updates: payload.Messages,
          };
        }
      )
      .addMatcher(
        api.endpoints.getQuestionById.matchFulfilled,
        (state: IState, { payload }: { payload: TGetQuestionByIdResponse }) => {
          updateStateFromPayload(state, payload);
        }
      )
      .addMatcher(
        api.endpoints.getUserInfoById.matchFulfilled,
        (state: IState, { payload }: { payload: IUser }) => {
          return { ...state, user: payload };
        }
      )
      .addMatcher(
        api.endpoints.getAppointmentById.matchFulfilled,
        (
          state: IState,
          { payload }: { payload: { Appointment: [IAppointment] } }
        ) => {
          return { ...state, appointment: payload.Appointment[0] };
        }
      );
  },
});

export const { actions, reducer } = slice;
export default slice.reducer;
