import styles from "./videoCallWindow.module.css";
import React, { useEffect, useRef, useState } from "react";
import incomingCallSound from "../../sounds/incomingCallSound.wav";
import { chatStateSelector } from "../../services/selectors";
import { useSelector } from "react-redux";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import { useActions } from "../../services/hooks/useActions";
import { toggleMuteMic, toggleVideo } from "../../utils/utils";
import acceptCall from "../../images/answer_call_icon.svg";
import endCall from "../../images/end_call_icon.svg";
// import microphoneIcon from "../../images/microphone_icon.svg";
// import microphoneSlashIcon from "../../images/microphone_slash_icon.svg";

interface IVideoCallWindowProps {
  onClose: () => void;
  answerCall: () => void;
  leaveCall: () => void;
  myStream: MediaStream | undefined;
}

function VideoCallWindow({
  onClose,
  answerCall,
  leaveCall,
  myStream,
}: IVideoCallWindowProps) {
  const myVideo = useRef<HTMLVideoElement>(null);
  const userVideo = useRef<HTMLVideoElement>(null);
  const chatState = useSelector(chatStateSelector);

  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);

  const { setCallAccepted } = useActions();
  // const videoTracks = chatState?.userStream?.getVideoTracks();

  // console.log(chatState);
  // console.log("Video Tracks:", myStream?.getVideoTracks());
  // myStream?.getVideoTracks().forEach((track) => {
  //   console.log("Track State:", track.readyState, "Enabled:", track.enabled);
  // });
  //
  // if (videoTracks?.length === 0) {
  //   console.log("No video tracks available");
  // } else {
  //   console.log("Video tracks found", videoTracks);
  // }
  // const userVideoElement = userVideo.current;
  // userVideoElement?.addEventListener("error", (event) => {
  //   console.error("Error with video playback:", event);
  // });

  useEffect(() => {
    if (myVideo?.current) {
      myVideo.current.srcObject = myStream as MediaProvider;
    }
    if (userVideo?.current) {
      userVideo.current.srcObject = chatState.userStream as MediaProvider;
    }
    console.log(
      "Is srcObject set?",
      userVideo?.current?.srcObject === chatState.userStream
    );

    return () => {
      // Cleanup: Ensure video is enabled when the component unmounts
      if (myStream) {
        myStream.getVideoTracks().forEach((track) => {
          track.enabled = true; // Re-enable the video track
        });
        setIsVideoEnabled(true); // Reset state for video toggle
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myVideo, userVideo, chatState.userStream, chatState.myStream]);
  const { avatar } = chatState.call.from;

  return (
    <>
      <div className={`${styles.window} `}>
        {!chatState.callAccepted && (
          <audio src={incomingCallSound} autoPlay={true} />
        )}
        <div className={styles.videoContainer}>
          <h3 className={styles.header}>
            {chatState.call.from.name} מתקשר/ת...
          </h3>

          {myStream && (
            <>
              <video
                playsInline
                ref={myVideo}
                muted
                autoPlay
                className={styles.myVideo}
              />
              <div className={styles.userVideoWindow}>
                {!chatState.callAccepted ? (
                  <div className={`${styles.outgoingCall}`}>
                    <div className={styles.pulse} />
                    {avatar && (
                      <img
                        src={avatar}
                        alt={"avatar"}
                        className={`${styles.avatar}`}
                      />
                    )}
                  </div>
                ) : (
                  <video
                    playsInline
                    ref={userVideo}
                    autoPlay
                    className={styles.userVideo}
                  />
                )}
                <div className={styles.buttonsBar}>
                  {!chatState.callAccepted ? (
                    <button
                      type={"button"}
                      className={`hover ${styles.btn}`}
                      onClick={() => {
                        setCallAccepted(true);
                        answerCall();
                      }}
                    >
                      <img
                        alt={"קבל שיחה"}
                        className={styles.btn__image}
                        src={acceptCall}
                      />
                    </button>
                  ) : (
                    <>
                      <button
                        type={"button"}
                        className={`hover ${styles.btn} `}
                        onClick={() => {
                          toggleMuteMic(myStream, setIsMicMuted);
                        }}
                      >
                        {/*<img*/}
                        {/*  className={` ${styles.btn_type_mute} ${styles.btn__image}`}*/}
                        {/*  alt={"mute"}*/}
                        {/*  src={isMicMuted ? microphoneIcon : microphoneSlashIcon}*/}
                        {/*/>*/}

                        <span
                          className={`${styles.btn_type_mute} ${
                            isMicMuted && styles.btn_type_unmute
                          }`}
                        />
                      </button>
                      <button
                        type={"button"}
                        className={`hover ${styles.btn} `}
                        onClick={() => {
                          toggleVideo(myStream, setIsVideoEnabled);
                        }}
                      >
                        <span
                          className={`${styles.btn_type_mute} ${
                            styles.btn_type_video
                          } ${
                            !isVideoEnabled && styles.btn_type_video_inactive
                          }`}
                        />
                      </button>
                    </>
                  )}

                  <button
                    type={"button"}
                    className={`hover ${styles.btn}`}
                    onClick={() => {
                      leaveCall();
                    }}
                  >
                    <img
                      alt="לחצן סיום שיחה"
                      className={styles.btn__image}
                      src={endCall}
                    />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalOverlay onClick={onClose} />
    </>
  );
}

export default VideoCallWindow;
