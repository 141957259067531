import { IMessage } from "../../utils/types";
import messageStyles from "./message.module.css";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import { getTimeFromTimestamp, venchyData } from "../../utils/utils";

function Message({
  message,
  isOwnMessage,
}: {
  message: IMessage;
  isOwnMessage: boolean;
}) {
  const messageClassname = isOwnMessage
    ? messageStyles.ownMessage
    : messageStyles.message;
  const containerClassname = isOwnMessage
    ? messageStyles.ownMessageContainer
    : messageStyles.messageContainer;

  const avatar = isOwnMessage ? avatarPlaceholder : venchyData.avatar;
  const alt = isOwnMessage ? "מציין מיקום של דמות" : venchyData.name;

  return (
    <>
      <div className={containerClassname}>
        <img src={avatar} alt={alt} className={messageStyles.avatar} />
        <div className={messageClassname}>
          <p className={messageStyles.text}>
            {message.message?.split("\n").map((part, index) => (
              <span key={index} className={messageStyles.textPart}>
                {part}
              </span>
            ))}
          </p>
          <p
            className={`${messageStyles.time} ${
              isOwnMessage && messageStyles.time_ownMessage
            }`}
          >
            {getTimeFromTimestamp(message.date)}
          </p>
        </div>
      </div>
    </>
  );
}

export default Message;
