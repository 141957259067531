import styles from "./chatNote.module.css";
import { Link } from "react-router-dom";

function ChatNote({ text, link }: { text: string; link?: string }) {
  return (
    <p className={styles.note}>
      {text}
      {link && <Link to={"/"}>{link}</Link>}
    </p>
  );
}

export default ChatNote;
