import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import prevencherChatStyles from "./chat.module.css";
import Message from "../message/message";
import { delay, formatDateTimeInIsrael } from "../../utils/utils";
import { useAppSelector } from "../../services/hooks";
import {
  messagesSelector,
  currentQuestionSelector,
  optionsSelector,
  currentQuestionIdSelector,
  errorSelector,
  allAnswersSelector,
  allQuestionsSelector,
  // questionnaireCompaniesIdSelector,
  userSelector,
} from "../../services/selectors";
import { useActions } from "../../services/hooks/useActions";
import Loader from "../loader/loader";
import {
  useInsertAllAnswersQuestionsMutation,
  useLazyGetQuestionByIdQuery,
  useLazyGetUserInfoByIdQuery,
  useLazyGetQuestionsByQuestionnaireIdQuery,
} from "../../services/api/api";
import { TQuestionOption } from "../../services/types";
import { useParams } from "react-router-dom";

function LinkedPageChat() {
  const { guid, questionnaireId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);
  const lastElRef = useRef<HTMLDivElement | null>(null);

  const [getUserInfo, { isLoading: isUserLoading }] =
    useLazyGetUserInfoByIdQuery();
  const [getCurrentQuestionnaireById, { isLoading: isQuestionnaireLoading }] =
    useLazyGetQuestionsByQuestionnaireIdQuery();
  const [getQuestionById] = useLazyGetQuestionByIdQuery();
  const [insertAllAnswers] = useInsertAllAnswersQuestionsMutation();

  const user = useAppSelector(userSelector);
  // console.log(user);
  const messages = useAppSelector(messagesSelector);
  const options = useAppSelector(optionsSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const error = useAppSelector(errorSelector);
  const allAnswers = useAppSelector(allAnswersSelector);
  const allQuestions = useAppSelector(allQuestionsSelector);
  const { setMessages, setOptions, setAnswers } = useActions();
  const [isLoading, setIsLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [sendForm, setSendForm] = useState(false);

  useEffect(() => {
    if (questionnaireId) {
      getCurrentQuestionnaireById(questionnaireId);
    }
  }, [getCurrentQuestionnaireById, questionnaireId]);

  useEffect(() => {
    if (guid) {
      getUserInfo(guid);
    }
  }, [getUserInfo, guid]);

  useEffect(() => {
    if (
      currentQuestion &&
      !currentQuestion.NextQuestionId &&
      currentQuestion.DataTypesId !== 1
    ) {
      setSendForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, insertAllAnswers, setSendForm]);

  useEffect(() => {
    if (sendForm) {
      insertAllAnswers({
        answers: allAnswers,
        companyId: user?.companyId || 1,
        questionnaireCompaniesId: user?.questionnaireCompaniesId || 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendForm, insertAllAnswers, allAnswers]);

  useEffect(() => {
    const nextQId = allQuestions && allQuestions[0]?.Id;
    if (nextQId && nextQId !== 0 && questionnaireId) {
      setIsLoading(true);
      delay(1500).then(() => {
        getQuestionById({
          nextQuestionId: nextQId,
          skip: false,
          questionnaireId,
        }).then(() => setIsLoading(false));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId, allQuestions, getQuestionById, setMessages]);

  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    if (currentQuestion?.DataTypesId === 2) {
      currentQuestion?.Id === 72 || currentQuestion?.Id === 19
        ? setShowTextArea(true)
        : setShowInput(true);
    }
    if (currentQuestion?.DataTypesId === 5) {
      setIsLoading(true);
      setOptions([]);
      delay(1500).then(() => {
        if (currentQuestion.NextQuestionId && questionnaireId) {
          getQuestionById({
            nextQuestionId: currentQuestion.NextQuestionId,
            skip: false,
            questionnaireId: questionnaireId,
          }).then(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, getQuestionById, setOptions]);

  const onOptionClick = (option: TQuestionOption) => {
    setOptions([]);
    setIsLoading(true);
    setMessages({
      messageId: `o${option.Id}`,
      id: user?.id || null,
      date: formatDateTimeInIsrael(new Date()),
      unread: false,
      message: option.Desc,
      nextQuestionId: option.NextQuestionId,
    });
    setAnswers({
      Id: "0",
      QuestionnaireId: questionnaireId,
      QuestionsId: currentQuestionId.toString(),
      UsersId: user?.id || null,
      Answer: null,
      QuestionsOptionsIds: option.Id.toString(),
      // ...(id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
    });
    delay(1500).then(() => {
      setIsLoading(false);
      const nextQId = option.NextQuestionId || currentQuestion?.NextQuestionId;
      if (nextQId && nextQId !== 0) {
        questionnaireId &&
          getQuestionById({
            nextQuestionId: nextQId,
            questionnaireId,
          });
      } else {
        setSendForm(true);
      }
    });
  };

  const onInputSubmit = async () => {
    if (!inputValue) {
      return;
    }
    setShowInput(false);
    setShowTextArea(false);
    setIsLoading(true);
    setMessages({
      messageId: `${currentQuestionId}${inputValue}`,
      id: user?.id || null,
      date: formatDateTimeInIsrael(new Date()),
      unread: false,
      message: inputValue,
      nextQuestionId: currentQuestion?.NextQuestionId,
    });
    delay(1500).then(() => {
      setAnswers({
        Id: "0",
        QuestionnaireId: questionnaireId,
        QuestionsId: currentQuestionId.toString(),
        UsersId: user?.id || null,
        Answer: inputValue,
        // ...(id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
      });
      setIsLoading(false);
      setInputValue("");
      const nextQId = currentQuestion?.NextQuestionId;
      if (nextQId && nextQId !== 0) {
        questionnaireId &&
          getQuestionById({
            nextQuestionId: nextQId,
            questionnaireId,
          });
      } else {
        setSendForm(true);
      }
    });
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let sanitizedContent = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
      ALLOWED_ATTR: [], // Allow no attributes
    });
    currentQuestion?.Id === 24
      ? handleTelInputChange(e)
      : setInputValue(sanitizedContent);
  };
  const handleTelInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setInputValue(onlyNumbers);
  };
  const scrollToBottom = () => {
    lastElRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      onInputSubmit();
    }
  };
  const handleFocus = () => {
    inputRef?.current &&
      inputRef.current.scrollIntoView({ behavior: "smooth" });
  }; //for ios mobile

  if (isQuestionnaireLoading || isUserLoading) return <Loader />;
  return (
    <div
      className={`${prevencherChatStyles.section} ${prevencherChatStyles.section_POC}`}
    >
      {messages.length > 0 &&
        messages.map((message) => (
          <Message
            key={message.messageId}
            isOwnMessage={user?.id.toString() === message.id.toString()}
            message={message}
          />
        ))}
      {options.length > 0 && (
        <ul
          className={`${prevencherChatStyles.variants} ${prevencherChatStyles.variants_poc}`}
        >
          {options.map((o) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={o.Id}
              onClick={() => {
                onOptionClick(o);
              }}
            >
              <span>{o.Desc}</span>
            </li>
          ))}
        </ul>
      )}
      {showInput && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <input
            type={"text"}
            ref={inputRef}
            onFocus={handleFocus}
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            maxLength={currentQuestion?.Id === 23 ? 10 : 300}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
            onClick={() => {}}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {showTextArea && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <textarea
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      <div
        className={`loader ${
          (isLoading || isQuestionnaireLoading) && "loader_visible"
        }`}
      >
        <Loader />
        <p className={prevencherChatStyles.typing}>ונצ'י מקליד</p>
      </div>
      {error && <p className={"error"}>{error}</p>}
      <div ref={lastElRef} />
    </div>
  );
}

export default LinkedPageChat;
