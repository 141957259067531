import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DOMPurify from "dompurify";
import chatStyles from "./chatTherapist.module.css";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import ChatNote from "../chatNote/chatNote";
import useTypingIndicator from "../../hooks/useTypingIndicator";
import { IUserChatData } from "../../services/types";
import MessageTest from "../message/messageTest";
import { useAppSelector } from "../../services/hooks";
import {
  appointmentSelector,
  chatConnectionSelector,
} from "../../services/selectors";
import { TYPING_CODE } from "../../utils/utils";
import TypingNote from "../typingNote/typingNote";

const ChatTherapist = ({
  receiver,
  handleSendMessage,
}: {
  receiver: IUserChatData | undefined;
  handleSendMessage: (value: string, type: string) => void;
}) => {
  const messagesEndRef = useRef(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const connection = useAppSelector(chatConnectionSelector);
  const appointment = useAppSelector(appointmentSelector);
  const isConnected = connection?.state === "Connected";
  const inputPlaceholder = isConnected ? "כתוב הודעה" : "אינך מחובר";

  const [value, setValue] = useState("");
  const { isTyping, startTyping } = useTypingIndicator(4000);

  useAutosizeTextArea(textAreaRef.current, value);
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" && event.ctrlKey) {
        event.preventDefault();
        setValue((prev) => `${prev}\n`); // Add a new line to the text
      } else if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (isConnected) {
          handleSendMessage(value, "Message");
          setValue("");
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSendMessage, value, isConnected]);

  useEffect(() => {
    if (isConnected) {
      handleSendMessage(`${TYPING_CODE}:${isTyping ? 1 : 0}`, TYPING_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [receiver?.messages.length, isTyping]);

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      startTyping();
      let sanitizedContent = DOMPurify.sanitize(e.target.value, {
        ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
        ALLOWED_ATTR: [], // Allow no attributes
      });
      setValue(sanitizedContent);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={chatStyles.container}>
      <div className={chatStyles.nameContainer}>
        <img
          src={avatarPlaceholder}
          alt={appointment?.TherapistFullName || ""}
          className={chatStyles.avatar}
        />
        <div className={chatStyles.info}>
          <h1 className={chatStyles.name}>
            {appointment?.TherapistFullName || ""}
          </h1>
          <p className={chatStyles.online}>
            {receiver?.online ? "מחובר" : "לא מחובר"}
          </p>
        </div>
      </div>
      {receiver?.messages.length ? (
        <div className={chatStyles.messagesWrapper}>
          {receiver.messages.map((m, index) => (
            <div key={index} className={chatStyles.dateSection}>
              <MessageTest
                message={m}
                isOwnMessage={receiver.userId !== m.senderId}
              />
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <div className={chatStyles.text}>אין הודעות עדיין</div>
      )}
      {appointment?.EndTime && (
        <ChatNote
          text={"פגישה זו אינה פעילה יותר."}
          link={"לפתיחת פגישה חדשה"}
        />
      )}
      {receiver?.isTyping && <TypingNote name={receiver.fullName} />}
      <div className={chatStyles.replyBar}>
        <textarea
          className={`${chatStyles.message} ${chatStyles.textArea}`}
          ref={textAreaRef}
          rows={1}
          placeholder={inputPlaceholder}
          onChange={handleInput}
          value={value}
          disabled={!isConnected || !!appointment?.EndTime}
        />
        <button
          className={`${chatStyles.btn} ${chatStyles.sendBtn} ${
            !value.trim() && chatStyles.sendBtn_disabled
          }`}
          onClick={() => {
            if (isConnected) {
              handleSendMessage(value, "Message");
              setValue("");
            }
          }}
          disabled={!value.trim() || !isConnected || !!appointment?.EndTime}
        />
      </div>
    </div>
  );
};

export default ChatTherapist;
