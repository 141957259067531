import { Buffer } from "buffer";
import process from "process";

import React from "react";
import ReactDOM from "react-dom/client";
import store from "./services/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./components/app/App";

window.Buffer = Buffer;
window.process = process;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
