import popupStyles from "./callEndedNotification.module.css";
import doctor from "../../images/questionnaire_doctor.png";

function CallEndedNotificationPopup({
  onClose,
  by,
}: {
  onClose: () => void;
  by: string;
}) {
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}> השיחה הסתיימה על ידי {by}</h2>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default CallEndedNotificationPopup;
