import popupStyles from "./userExistPopup.module.css";
import doctor from "../../images/questionnaire_doctor.png";
import { BPREVEN_CLIENT_URL } from "../../utils/utils";
// import { IPopupWithoutProps } from "../../../types/types";
// import CloseButton from "../../closeButton/closeButton";

function UserExistPopup({ guid }: { guid: string | null }) {
    const onClose = () => {
        if (guid) {
            window.location.href = `${BPREVEN_CLIENT_URL}/signin/private?Guid=${guid}`

        }
        else {
            window.location.href = `${BPREVEN_CLIENT_URL}/login`;
        }
    }
    return (
        <article className={popupStyles.popup}>
            <h2 className={popupStyles.popup__title}>אתה מוכר לנו...</h2>
            <p className={popupStyles.popup__text}>
                מספר הטלפון שלך כבר קיים במערכת, הינך מועבר ל{!guid ? "כניסה למערכת" : "סיום הרישום"}      </p>
            <img
                src={doctor}
                alt="Docktor picture"
                className={popupStyles.popup__img}
            />
            <button
                type="button"
                className={`${popupStyles.popup__nextBtn} hover`}
                onClick={onClose}
            >
                אישור
            </button>
        </article>
    );
}

export default UserExistPopup;
