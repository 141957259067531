import React from "react";
import ReactDOM from "react-dom";
import modalStyles from "./modal.module.css";

interface IModal {
  children: JSX.Element;
  onClose?: () => void;
  prevencherAnswer?: boolean;
  small?: boolean;
}
const Modal = ({
  children,
  onClose,
  prevencherAnswer,
  small,
}: IModal): JSX.Element => {
  const modalRoot = document.getElementById("modals") as HTMLDivElement;

  return ReactDOM.createPortal(
    <>
      <div
        className={`${modalStyles.popupWindow}  ${
          prevencherAnswer && modalStyles.popupWindow_type_wide
        } ${small && modalStyles.popupWindow_type_small}`}
      >
      { onclose && <button
          className={modalStyles.popupWindow__closeBtn}
          onClick={onClose}
        />}
        {children}
      </div>
    </>,
    modalRoot
  );
};

export default Modal;
