import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { actions as userActions } from "../slices/slice";
import { actions as chatActions } from "../slices/chat.slice";
import { useMemo } from "react";

const rootActions = {
  ...userActions,
  ...chatActions,
};
export const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};
