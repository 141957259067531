import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";
import { IChatMessage, IUserChatData } from "../types";
import { ICall } from "../../utils/types";
import { MutableRefObject } from "react";

interface ChatState {
  isConnected: boolean;
  connection?: HubConnection;
  chatData: IUserChatData[];
  call: ICall;
  callAccepted: boolean;
  callEnded: undefined | string;
  openModalCallEnded: boolean;
  myStream: MediaStream | undefined;
  userStream: MediaStream | undefined;
  offer: string | undefined;
  myVideo: MutableRefObject<HTMLVideoElement> | null;
  userVideo: MutableRefObject<HTMLVideoElement> | null;
  isMicMuted: boolean;
}
export const videoCallInitialState: ICall = {
  isReceivingCall: false,
  from: {
    name: "",
    // avatar: "",
  },
  // signal: { type: "offer" },
};
const initialState: ChatState = {
  isConnected: false,
  chatData: [],
  call: videoCallInitialState,
  offer: undefined,
  callAccepted: false,
  callEnded: undefined,
  openModalCallEnded: false,
  myStream: undefined,
  userStream: undefined,
  myVideo: null,
  userVideo: null,
  isMicMuted: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setWsConnection(state, action: PayloadAction<HubConnection>) {
      state.connection = action.payload;
    },
    setConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    setChatData(state, { payload }: { payload: IUserChatData[] }) {
      console.log(payload);
      state.chatData = payload;
    },
    addMessage(state, { payload }: { payload: IChatMessage }) {
      state.chatData = state.chatData.map((u) =>
        u.userId === payload.senderId || u.userId === payload.receiverId
          ? { ...u, messages: [...u.messages, payload] }
          : u
      );
    },
    endCall(state, { payload }: { payload: IChatMessage }) {
      return {
        ...state,
        call: videoCallInitialState,
        callEnded: payload.content.split("by:")[1],
      };
    },
    setUserConnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatData: state.chatData.map((usr) =>
          usr.userId === payload ? { ...usr, online: true } : usr
        ),
      };
    },
    setUserTyping(state, { payload }: { payload: IChatMessage }) {
      return {
        ...state,
        chatData: state.chatData.map((usr) =>
          usr.userId === payload.senderId
            ? {
                ...usr,
                isTyping: payload.content.split(":")[1] === "1",
              }
            : usr
        ),
      };
    },
    setUserDisconnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatData: state.chatData.map((usr) =>
          usr.userId === payload ? { ...usr, online: false } : usr
        ),
      };
    },
    setCall(state, { payload }: { payload: ICall }) {
      return {
        ...state,
        call: payload,
      };
    },
    setCallAccepted(state, { payload }: { payload: boolean }) {
      return {
        ...state,
        callAccepted: payload,
      };
    },
    setCallEnded(state, { payload }: { payload: string | undefined }) {
      return {
        ...state,
        callEnded: payload,
      };
    },
    setOpenModalCallEnded(state, { payload }: { payload: boolean }) {
      return {
        ...state,
        openModalCallEnded: payload,
      };
    },
    setStartCall(state, { payload }: { payload: boolean }) {
      return {
        ...state,
        startCall: payload,
      };
    },
    // setMyStream(state, { payload }: { payload: MediaStream | undefined }) {
    //   console.log(payload);
    //   return {
    //     ...state,
    //     myStream: payload,
    //   };
    // },
    setUserStream(state, { payload }: { payload: MediaStream | undefined }) {
      return {
        ...state,
        userStream: payload,
      };
    },
    setOffer(state, { payload }: { payload: string }) {
      console.log(payload);
      return {
        ...state,
        offer: payload,
      };
    },
  },
});
export const { actions } = chatSlice;
export const {
  setWsConnection,
  setConnected,
  addMessage,
  setUserTyping,
  endCall,
} = chatSlice.actions;
export default chatSlice.reducer;
