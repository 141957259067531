import chatSidebarStyles from "./chatSidebar.module.css";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import { useAppSelector } from "../../services/hooks";
import {
  appointmentSelector,
  chatDataSelector,
} from "../../services/selectors";
import { Dispatch, SetStateAction } from "react";
import { IUserChatData } from "../../services/types";

function ChatSideBarTest({
  setReceiver,
}: {
  setReceiver: Dispatch<SetStateAction<IUserChatData | undefined>>;
}) {
  const chatData = useAppSelector(chatDataSelector);
  const appointment = useAppSelector(appointmentSelector);

  return (
    <section className={`${chatSidebarStyles.sidebar}`}>
      <ul
        className={`${chatSidebarStyles.sidebar__therapists} ${chatSidebarStyles.scrollable} scroll`}
      >
        <li className={`${chatSidebarStyles.sidebar__li} `}>
          <div
            className={`${chatSidebarStyles.sidebar__online} ${
              appointment &&
              chatData?.find((item) => item.userId === appointment.TherapistsId)
                ?.online &&
              chatSidebarStyles.sidebar__online_active
            }
              `}
          >
            <img
              className={chatSidebarStyles.sidebar__avatar}
              src={avatarPlaceholder}
              alt={appointment?.TherapistFullName || ""}
            />
          </div>
          <div className={chatSidebarStyles.sidebar__therapist}>
            <p className={chatSidebarStyles.sidebar__name}>
              {appointment?.TherapistFullName || ""}
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default ChatSideBarTest;
