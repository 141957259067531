import popupStyles from "../callEndedNotification/callEndedNotification.module.css";
import mediaDevicesErrorPopupStyles from "./mediaDevicesErrorPopup.module.css";
import doctor from "../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../utils/types";

function MediaDevicesErrorPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <h2
        className={`${popupStyles.popup__title} ${mediaDevicesErrorPopupStyles.popup__title}`}
      >
        עליך לאפשר לאפליקציה זו גישה למצלמה ולמיקרופון שלך כדי להשתמש בצ'אט
        וידאו.
      </h2>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={`${popupStyles.popup__img} ${mediaDevicesErrorPopupStyles.popup__img}`}
      />
      <p className={mediaDevicesErrorPopupStyles.popup__text}>
        1. אם מופיעה התראה בדפדפן לבקש הרשאה, לחצ/י על 'אפשר'
      </p>
      <p className={mediaDevicesErrorPopupStyles.popup__text}>
        2. בגוגל כרום: לחצ/י על האייקון של המנעול בשורת הכתובת, ולאחר מכן אפשר/י
        גישה למצלמה ולמיקרופון.
      </p>
      <p className={mediaDevicesErrorPopupStyles.popup__text}>
        3. במוזילה פיירפוקס: לחצ/י על האייקון של המנעול בשורת הכתובת ובחר/י
        באפשרות לאפשר גישה.
      </p>
      <p className={mediaDevicesErrorPopupStyles.popup__text}>
        4. רעננ/י את הדף לאחר אישור הגישה
      </p>
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default MediaDevicesErrorPopup;
